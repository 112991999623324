<template>
  <div class="p-4 bg-gray-50 min-h-screen">
    <div class="bg-white rounded-lg shadow-sm">
      <div class="flex items-center justify-between border-b p-4">
        <h1 class="text-xl font-semibold text-gray-800">Kompania</h1>
      </div>
      
      <div class="grid grid-cols-12 gap-4 p-4">
        <!-- Company Parameters -->
        <div class="col-span-3">
          <div class="bg-white rounded-lg border">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Parametrat e kompanise</h2>
              <button @click.prevent="show_krudh('configuration')" class="text-gray-500 hover:text-gray-700">
                <i class="fa-solid fa-gears"></i>
              </button>
            </div>
            <div class="p-2">
              <table class="w-full text-sm">
                <tbody>
                  <tr v-for="conf in configurations_data" :key="conf.id" class="border-b last:border-0">
                    <td class="py-2 px-3">{{conf.config_key}}</td>
                    <td class="py-2 px-3 text-right font-medium">{{conf.config_value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Payment Section -->
        <div class="col-span-4">
          <div class="bg-white rounded-lg border h-full">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Pagesa</h2>
            </div>
            <div class="divide-y">
              <!-- Payment Terms -->
              <div class="p-3">
                <div class="flex items-center justify-between mb-2">
                  <span class="text-sm font-medium text-gray-600">Termat e pageses</span>
                  <button @click.prevent="show_payment_terms_krudh('payment_terms')" class="text-gray-500 hover:text-gray-700">
                    <i class="fa-solid fa-gears"></i>
                  </button>
                </div>
                <div class="max-h-32 overflow-y-auto">
                  <table class="w-full text-sm">
                    <tbody>
                      <tr v-for="pt in payment_terms" :key="pt.guid" class="border-b last:border-0">
                        <td class="py-1.5 px-2">{{pt.name}}</td>
                        <td class="py-1.5 px-2 text-gray-500">{{pt.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Payment Methods -->
              <div class="p-3">
                <div class="flex items-center justify-between mb-2">
                  <span class="text-sm font-medium text-gray-600">Format e pageses</span>
                  <button @click.prevent="show_payment_methods_krudh('payment_methods')" class="text-gray-500 hover:text-gray-700">
                    <i class="fa-solid fa-gears"></i>
                  </button>
                </div>
                <div class="max-h-32 overflow-y-auto">
                  <table class="w-full text-sm">
                    <tbody>
                      <tr v-for="pm in payment_methods" :key="pm.guid" class="border-b last:border-0">
                        <td class="py-1.5 px-2">{{pm.name}}</td>
                        <td class="py-1.5 px-2 text-gray-500">{{pm.description}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Brands Section -->
        <div class="col-span-5">
          <div class="bg-white rounded-lg border h-full">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Marka</h2>
              <button @click.prevent="show_brands_krudh('brands')" class="text-gray-500 hover:text-gray-700">
                <i class="fa-solid fa-gears"></i>
              </button>
            </div>
            <div class="p-3">
              <simple-pagination 
                :items="brands_list" 
                :itemsPerPage="8" 
                :is_grid="true" 
                :grid_cols="'grid-cols-4'"
                :show_more="true"
                class="gap-3"
              />
            </div>
          </div>
        </div>

        <!-- Bottom Row -->
        <div class="col-span-3">
          <div class="bg-white rounded-lg border">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Departamente</h2>
              <button @click.prevent="show_departments_krudh('departments')" class="text-gray-500 hover:text-gray-700">
                <i class="fa-solid fa-gears"></i>
              </button>
            </div>
            <div class="p-2">
              <div v-for="department in departments" 
                   :key="department.guid" 
                   class="py-2 px-3 text-sm uppercase hover:bg-gray-50 border-b last:border-0">
                {{ department.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- Warranties -->
        <div class="col-span-4">
          <div class="bg-white rounded-lg border">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Garanci</h2>
              <button @click.prevent="show_warranties_krudh('warranties')" class="text-gray-500 hover:text-gray-700">
                <i class="fa-solid fa-gears"></i>
              </button>
            </div>
            <div class="p-3">
              <simple-pagination 
                :items="warranties_list" 
                :itemsPerPage="4" 
                :has_refresh="true"
                @refresh_data="get_warranties_list"
              />
            </div>
          </div>
        </div>

        <!-- Rejection Reasons -->
        <div class="col-span-5">
          <div class="bg-white rounded-lg border">
            <div class="flex items-center justify-between p-3 border-b">
              <h2 class="font-semibold text-gray-700">Arsyet per refuzimin e shitjes</h2>
              <button @click.prevent="show_rejecton_reasons_krudh('rejecton_reasons')" class="text-gray-500 hover:text-gray-700">
                <i class="fa-solid fa-gears"></i>
              </button>
            </div>
            <div class="p-2">
              <div v-for="reason in rejecton_reasons" 
                   :key="reason.guid" 
                   class="py-2 px-3 text-sm hover:bg-gray-50 border-b last:border-0">
                {{ reason.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { $vfm } from 'vue-final-modal'
import { defineAsyncComponent } from "vue"
import ConfigurationService from "@/services/Configuration.js"
import ProductsServices from "@/services/Product";
import DepartmentsServices from "@/services/Department";
import { useKrudhStore } from "@/stores/krudh_communication";
import { storeToRefs } from "pinia";
import simplePagination from "@/components/simple_pagination.vue";
// import DaForm from "@/components/daform/index.vue";
export default {
  components: {
    simplePagination
  },
  data() {
    return {
      form: null,
      configurations_data: [],
      // payment_methods: [],
      // payment_terms: [],
      krudh_communication_data: storeToRefs(useKrudhStore()),
      payments_data: [],
      brands_list: [],
      warranties_list: [],
      departments: [],
      rejecton_reasons: [],


    }
  },
  created() {
    this.get_company_data()
  },
  methods: {
    async get_company_data() {
      this.configurations_data = await ConfigurationService.getConfigurations()
      this.payments_data = await ConfigurationService.getPaymentsConfiguration()
      this.brands_list = await ConfigurationService.getBrandsList()
      this.departments = await DepartmentsServices.getDepartmentsList()
      // get rejection_reasons ...
      this.rejecton_reasons = await ConfigurationService.getRejectionReasons()
      await this.get_warranties_list();

    },
    async get_warranties_list() {
      this.warranties_list = await ProductsServices.getWarranties()
    },
    show_krudh() {
      var params = {
        fields: [
          'config_key:Titulli i komfigurimit,config_value:Vlera e konfigurimit'
          // 'icon:Ikona,*name:Emri', 
          // 'description:Pershkrimi'
        ],
        entity: 'configurations',
        columns: ['icon:80px', 'name:130px'],
        title: 'Konfigurime'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_brands_krudh(entity) {
      var params = {
        fields: [
          'name:Emri i markes'
          // 'icon:Ikona,*name:Emri', 
          // 'description:Pershkrimi'
        ],
        sort: 'name',
        entity: entity,
        columns: ['name:80%'],
        title: 'Marka'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_payment_methods_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Format e pageses'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_payment_terms_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Termat e pageses'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    get_configuration_value(key) {
      var text = ""
      this.configurations_data.map(c => {
        if (c.config_key == key) return text = c.config_value
      })
      return text
    },
    show_departments_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Departamente'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_warranties_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'sort:Renditja',
          'is_default:Default:checkbox-field',
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Garancite',
        sort: 'sort',
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_rejecton_reasons_krudh(entity) {
      var params = {
        fields: [
          'text:Shpjegimi',
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Arsyet e refuzimit te shitjes'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    // show_sellers_krudh(entity){
    //   var params = {
    //     fields: [
    //       'name:Emri',
    //     ],
    //     entity: entity,
    //     columns: ['name:80%'],
    //     title: 'LIsta e shitesve te produkteve'
    //   }
    //   $vfm.show({
    //     component: defineAsyncComponent(
    //       () => import("@/components/krudh-modal.vue")
    //     ), params
    //   })
    // }


  },
  watch: {
    krudh_communication_data: {
      async handler(n) {
        if (n.entity == 'payment_terms' || n.entity == 'payment_methods') {
          // Update Payments
          this.payments_data = await ConfigurationService.getPaymentsConfiguration()
        }
        else if (n.entity == 'configurations') {
          // Update configurations
          this.configurations_data = await ConfigurationService.getConfigurations()
        }
        else if (n.entity == 'brands') {
          this.brands_list = await ConfigurationService.getBrandsList()
        }

        // Reset store variables
        this.krudh_communication_data.response = [],
          this.krudh_communication_data.entity = '',
          this.krudh_communication_data.is_new = false
      },
      deep: true
    }
  },
  computed: {
    payment_methods() {
      var a = []
      if (this.payments_data.payment_methods) a = this.payments_data.payment_methods
      return a
    },
    payment_terms() {
      var a = []
      if (this.payments_data.payment_terms) a = this.payments_data.payment_terms
      return a
    }
  }
}
</script>

<style></style>
